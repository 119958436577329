@import '../../global.scss';

.header {
    @include d_flex;
    justify-content: space-between;
    padding: 16px 16px;
    background-color: $white;
    box-shadow: $elevation_6;

    &--logo {
        display: block;
        width: 10vw;

        @media screen and (max-width: 1199px) {
            width: 16vw;
        }

        @media screen and (max-width: 991px) {
            width: 20vw;
        }

        @media screen and (max-width: 600px) {
            width: 32vw;
        }
    }

    &--user {
        @include d_flex;
        gap: 16px;
        @extend .body_3;
        color: $body;

        @media screen and (max-width: 600px) {
            flex-direction: column;
            gap: 0;
        }

        &-action {
            color: $white;
            background-color: $error;
            padding: 4px 12px;
            border-radius: 999px;
            text-transform: uppercase;
            cursor: pointer;

            @media screen and (max-width: 600px) {
                padding {
                    top: 2px;
                    bottom: 2px;
                }
            }
        }
    }
}