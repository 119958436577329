@import '../../global.scss';

.successPage {
    @include d_flex;
    flex-direction: column;
    background-color: $primary_50;
    min-height: 100vh;
    height: 100%;

    &-title {
        @extend .heading_1;
        color: $headings;
        margin-bottom: 28px;
        text-align: center;
    }

    .primary_btn {
        border: none;
        background: $button_gradient;
    }
}