@import '../../global.scss';

.purchaseQuestion {
    background-color: $primary_50;
    min-height: calc(100vh - 90px);
    height: 100%;
    @include d_flex;
    flex-direction: column;

    .handler {
        margin-bottom: 16px;
    }
}