@import '../../global.scss';

// Login
.login {
    @include d_flex;
    flex-direction: column;
    background-color: $primary_500;
    min-height: 100vh;
    height: 100%;

    .headingBox {
        padding: 24px 0 32px;
    }

    &-logo {
        @include img-fluid;
        width: 18vw;
        margin: 24px auto 4vw;

        @media (max-width: 991px) {
            width: 32vw;
        }

        @media screen and (max-width: 600px) {
            width: 48vw;
        }
    }

    &--box {
        @extend .lg_radius;
        background-color: $white;
        width: 90%;
        max-width: 584px;
        padding: 24px;
        margin: 0 auto 72px;

        @media (max-width: 1199px) {
            margin-bottom: 9.37vw;
        }
    }

    &--form {
        margin: 24px 0 0;

        .input_group_container {
            margin-bottom: 24px;
            
            .input_group_ {
                width: 100%;
            }
        }

        &-btn {
            background: $button_gradient;
            border: none;
            width: 100%;
        }
    }
}