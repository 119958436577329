@import './global.scss';

.customForm {
    background-color: $primary_50;
    min-height: calc(100vh - 90px);
    height: 100%;
    @include d_flex;
    flex-direction: column;

    .handler {
        margin: {
            top: 20px;
            bottom: 16px;
        };
    }

    &-head {
        @extend .heading_1;
        text-align: center;
        color: $headings;
        margin: 20px 0;
    }

    &--box {
        @extend .lg_radius;
        background-color: $white;
        width: 90%;
        max-width: 1200px;
        margin: 0 auto 20px;
        box-shadow: $elevation_6;
        // overflow: hidden;

        &-content {
            padding: 0 24px;
        }
    }

    &--action {
        @include d_flex;
        margin-top: 24px;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        overflow: hidden;

        &-child {
            flex-basis: 100%;
            border: none;
            border-radius: 0 !important;
        }
    }

    .input_group_container {
        margin-bottom: 20px;
        
        .input_group_ {
            width: 100%;
            height: auto;

            .input_label {
                width: 100%;
                @extend .body_3;
                color: $body;
                display: block;
                margin-bottom: 4px;
                text-align: left;
            }

            .input_type_text {
                height: 40px;
            }

            .custome_select > div {
                width: 100%;
            }
        }
    }

    // .radio-inline {
    //     @include d_flex;
    //     justify-content: flex-start;
    //     flex-wrap: wrap;
    //     width: 100%;

    //     @media screen and (max-width: 600px) {
    //         flex-direction: column;
    //         align-items: flex-start;
    //     }

    //     .radio--wrap {
    //         @extend .body_2;
    //         margin: 8px 0 !important;
    //         width: 25%;

    //         @media screen and (max-width: 991px) {
    //             width: 33.33%;
    //         }

    //         @media screen and (max-width: 600px) {
    //             width: 100%;
    //         }
    //     }
    // }
}