@import "../../global.scss";

.handler {
    &--shape {
        display: block;
        @extend .sm_radius;
        width: 44px;
        height: 6px;
        margin: 0 auto;
        background-color: $surface_background;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, .12) inset;
    }
}