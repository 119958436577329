@import '../../global.scss';

.notFound {
    @include d_flex;
    flex-direction: column;
    text-align: center;
    min-height: 100vh;
    background-color: $primary_50;

    &-title {
        font: {
            size: 10vw;
            weight: 700;
        };
        
        @media screen and (max-width: 600px) {
            font-size: 28vw;
        }
    }

    &-para {
        @extend .heading_5;
        color: $body;
        margin: 4px 0 32px;
    }

    &-action {
        @extend .md_radius;
        @extend .heading_6;
        display: inline-flex;
        color: $white;
        background-color: $primary_500;
        text-transform: uppercase;
        padding: 12px 24px;
        margin-bottom: 20px;
        box-shadow: $elevation-4;
        transition: .3s all ease-in;

        &:hover,
        &:focus {
            color: $white;
            box-shadow: $elevation-9;
        }
    }
}