@import '../../global.scss';

.progressBar {
    width: 100%;
    background-color: $line;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin-bottom: 20px;
    overflow: hidden;
    transition: all .3s ease-in;

    &--main {
        height: 10px;
        background-color: $success;
        transition: all .3s ease-in;
    }
}